import api from '@/guides/api'

export const list = ({
  category,
  list,
}: { category?: string; list?: string } = {}) => {
  let url = '/library'

  if (category) {
    url += '/' + category
  }

  if (list) {
    url += '/' + list
  }

  return api.get(url)
}

export const search = ({
  term,
  category,
  list,
}: { term?: string; category?: string; list?: string } = {}) => {
  let url = '/library'

  if (category) {
    url += '/' + category
  }

  if (list) {
    url += '/' + list
  }

  if (term) {
    url += `?term=${term}`
  }

  return api.get(url)
}

export const single = (slug: string | string[]) => {
  return api.get(`/library-card/${slug}`)
}
