<template>
  <div class="library" v-if="content && chromeData">
    <Hero :title="content.title" :picture="chromeData?.headers.library">
      <Search route="LibrarySearch" :category="category" :list="list" />
    </Hero>

    <div class="actions" v-if="category || list">
      <BackBtn />
    </div>

    <div class="wrapper" v-if="!loading">
      <div class="cards" v-if="list">
        <router-link
          class="m-xs"
          v-for="item in content.cards"
          :to="{ path: item.url }"
          :key="item.url"
          :href="item.url"
        >
          <Card :data="item" />
        </router-link>

        <p v-if="!content.cards">No content</p>
      </div>

      <div class="sections" v-else-if="category">
        <router-link
          v-for="item in content.subs"
          :key="item.url"
          class="m-xs"
          :to="{
            name: 'Library',
            params: {
              category: item.type.category,
              list: item.type.list,
            },
          }"
        >
          <Section :data="item" />
        </router-link>

        <!-- handle case where category can have directly cards -->
        <router-link
          class="m-xs"
          v-for="item in content.cards"
          :to="{ path: item.url }"
          :key="item.url"
        >
          <Card :data="item" />
        </router-link>

        <p v-if="!content.subs && !content.cards">No content</p>
      </div>

      <div class="categories" v-else>
        <router-link
          v-for="item in content.subs"
          :key="item.url"
          :to="{
            name: 'Library',
            params: {
              category: item.type.category,
            },
          }"
        >
          <Category :data="item" />
        </router-link>
      </div>
    </div>
  </div>
  <div v-else>
    <Error />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list } from '@/guides/endpoints/library'

import BackBtn from '@/components/BackBtn.vue'
import Card from '@/components/inventory/Card.vue'
import Category from '@/components/inventory/Category.vue'
import Error from '@/components/Error.vue'

import Hero from '@/components/Hero.vue'
import Search from '@/components/inventory/Search.vue'
import Section from '@/components/inventory/Section.vue'

import { push } from '@/inc/tracking'

export default defineComponent({
  props: {
    category: {
      type: String,
      default: '',
    },
    list: {
      type: String,
      default: '',
    },
  },
  components: {
    BackBtn,
    Card,
    Category,
    Error,
    Hero,
    Search,
    Section,
  },
  setup(props) {
    const chromeData = ref()
    const content = ref()
    const loading = ref(false)

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)
    })

    const fetchContent = () => {
      loading.value = true

      list({
        category: props.category,
        list: props.list,
      }).then(res => {
        if (res?.data?.content) {
          content.value = res.data.content
        }

        loading.value = false
      })

      push({
        name: 'category-library',
        category: 'category-library',
        action: 'click',
        label: props.category,
      })
    }

    fetchContent()

    watch(() => props.category, fetchContent)
    watch(() => props.list, fetchContent)

    return {
      chromeData,
      content,
      loading,
    }
  },
})
</script>

<style lang="scss" scoped>
.categories {
  width: 100%;
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(2, 1fr);
}

.sections {
  > * {
    display: block;
  }
}

.add {
  position: fixed;
  z-index: 20;
  bottom: 30px;
  right: 30px;

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }

  .bullet {
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    background: $regal-blue;
    border-radius: 50%;
  }

  p {
    display: none;
    font-size: 1.4rem;
    color: $tangerine;
    margin-right: 10px;
  }

  svg {
    fill: $c-white;
  }
}

@include mq(s) {
  .actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 30px;
    margin-top: -40px;

    .back-btn {
      position: static;
      margin: 0;
    }

    // .add {
    //   display: inline-flex;
    //   position: static;
    //   align-items: center;
    // }

    // .add p {
    //   display: block;
    // }

    // .add .bullet {
    //   width: 30px;
    //   height: 30px;

    //   svg {
    //     width: 13px;
    //     height: auto;
    //   }
    // }
  }
}

.cards > * {
  display: block;
}

@include mq(xl) {
  .sections {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);

    > * {
      margin: 0;
    }
  }

  .cards {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);

    > * {
      margin: 0;
    }
  }
}
</style>
