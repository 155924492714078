
import { defineComponent, ref, watch } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list } from '@/guides/endpoints/library'

import BackBtn from '@/components/BackBtn.vue'
import Card from '@/components/inventory/Card.vue'
import Category from '@/components/inventory/Category.vue'
import Error from '@/components/Error.vue'

import Hero from '@/components/Hero.vue'
import Search from '@/components/inventory/Search.vue'
import Section from '@/components/inventory/Section.vue'

import { push } from '@/inc/tracking'

export default defineComponent({
  props: {
    category: {
      type: String,
      default: '',
    },
    list: {
      type: String,
      default: '',
    },
  },
  components: {
    BackBtn,
    Card,
    Category,
    Error,
    Hero,
    Search,
    Section,
  },
  setup(props) {
    const chromeData = ref()
    const content = ref()
    const loading = ref(false)

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)
    })

    const fetchContent = () => {
      loading.value = true

      list({
        category: props.category,
        list: props.list,
      }).then(res => {
        if (res?.data?.content) {
          content.value = res.data.content
        }

        loading.value = false
      })

      push({
        name: 'category-library',
        category: 'category-library',
        action: 'click',
        label: props.category,
      })
    }

    fetchContent()

    watch(() => props.category, fetchContent)
    watch(() => props.list, fetchContent)

    return {
      chromeData,
      content,
      loading,
    }
  },
})
